//basic
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {toJS} from 'mobx';

//api

//style
import './Article.scss';

//custom components

//material-ui

//other libraries
import ReactHtmlParser from 'react-html-parser';
import Helmet from 'react-helmet';


class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        // console.group("Article");
        // console.log("componentDidMount props:", this.props);
        // console.groupEnd();
        // console.log("props", this.props)
        const { routerData, ...other} = this.props
        this.props.internal.setRouterData(routerData);
        this.props.internal.setGlobalLoader(false);
    }

    render () {
        let footerData = toJS(this.props.internal.footerData)
        // console.log("footerData", footerData, this.props.data)
        return (
            <div className={'article'} >
            <Helmet >
                    <title>
                        {
                            this.props.data.page_title &&
                            this.props.data.page_title !== undefined &&
                            this.props.data.page_title !== null
                                ?
                                this.props.data.page_title
                                :
                                this.props.data.name
                        }
                        {' '}
                        {
                            footerData &&
                            footerData !== null &&
                            footerData !== undefined &&
                            footerData.shop &&
                            footerData.shop.additional_page_title
                                ?
                                footerData.shop.additional_page_title
                                :
                                footerData &&
                                footerData !== null &&
                                footerData !== undefined &&
                                footerData.shop &&
                                footerData.shop.name
                                    ?
                                    footerData.shop.name
                                    :
                                    ""
                        }
                    </title>
                    <meta name="description"
                        content={`${
                            this.props.data.page_description &&
                            this.props.data.page_description !== undefined &&
                            this.props.data.page_description !== null
                                ?
                                this.props.data.page_description
                                :
                                ""
                        }
                        
                        `}
                    />

                    <meta itemProp="name" content={`elica.sk`}/>
                    <meta itemProp="description"
                        content={`${
                            this.props.data.page_description &&
                            this.props.data.page_description !== undefined &&
                            this.props.data.page_description !== null
                                ?
                                this.props.data.page_description
                                :
                                ""
                        }`}
                    />
                    <meta itemProp="image"
                        content={`${
                            footerData !== null &&
                            footerData !== undefined &&
                            footerData.shop &&
                            footerData.shop.email_asset_email_logo &&
                            footerData.shop.email_asset_email_logo !== null &&
                            footerData.shop.email_asset_email_logo !== undefined
                                ?
                                footerData.shop.email_asset_email_logo
                                :
                                ""
                            }`
                        }
                    />
                    <link rel="sitemap" type="application/xml" title="Sitemap" href={`https://b2b.ravaltrade.sk/storage/public/xml/sitemap-elica.xml`} />

                    <meta
                        property="og:title"
                        content={
                            this.props.data.page_title &&
                            this.props.data.page_title !== undefined &&
                            this.props.data.page_title !== null
                                ?
                                this.props.data.page_title
                                :
                                ""
                        }
                    />
                    <meta property="og:type" content="website"/>
                    <meta property="og:url"
                        content={`${
                            this.props.data.shop &&
                            this.props.data.shop.url !== undefined &&
                            this.props.data.shop.url !== null
                                ?
                                this.props.data.shop.url
                                :
                                ""
                        }
                    `}/>
                    <meta
                        property="og:image"
                        content={`${
                            footerData &&
                            footerData !== null &&
                            footerData !== undefined &&
                            footerData.shop &&
                            footerData.shop.additional_page_title
                                ?
                                footerData.shop.email_asset_email_logo
                                :
                                ""
                        }`}/>

                    <meta
                        property="twitter:domain"
                        content={
                            this.props.data.page_title &&
                            this.props.data.page_title !== undefined &&
                            this.props.data.page_title !== null
                                ?
                                this.props.data.page_title
                                :
                                ""
                        }
                    />
                </Helmet>
                <section className="main-header productDetail " >
                    <header>
                        <div className="container">
                            <h1 className={'title'}>{this.props.data.name}</h1>
                        </div>
                    </header>
                </section>
                <section className={'section-content'}>
                    <div className={'container bgGrayLight'}>
                        <div className={'content'}>
                            {ReactHtmlParser(this.props.data.content)}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default inject('internal')(observer(Article));
